/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.title {
  background: #f7f7f7;
}

.title .field-title a {
  display: block;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #89C6CC;
  font-size: 24px;
  color: #707070;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.title .field-title a:focus, .title .field-title a:hover {
  color: #878787;
}

.title h1 {
  margin-bottom: 1.46154em;
  font-size: 3.25em;
  font-weight: 500;
  line-height: 1em;
}
